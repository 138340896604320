<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data'/>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('miracle_deal.last_transactions') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <datatable-dropdown />
            </div>
          </div>
          <div class='card-body'>
            <div v-if="isLoading">
              <div class="text-center">{{ $t('commons.data_loading') }}...</div>
            </div>
            <table class="table table-striped table-bordered collapsed" style="width:100%" ref="kt_datatable" v-else>
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ $t('miracle_deal.keeper_to_striker') }}</th>
                <th scope="col">{{ $t('commons.currency_code') }}</th>
                <th scope="col">{{ $t('commons.quantity') }}</th>
                <th scope="col">{{ $t('commons.duration') }}</th>
                <th scope="col">{{ $t('miracle_deal.countdown') }}</th>
                <th scope="col">{{ $t('commons.status') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in skmatch" :key="'skmatch-' + index">
                <th class="font-weight-bolder">{{ item.id }}</th>
                <td>{{ item.s_name }}. {{ item.s_surname }}. -> {{ item.k_name }}. {{ item.k_surname }}.</td>
                <td>
                  <span style="width: 250px;">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-40 flex-shrink-0">								
                        <div class="media align-items-center">
                          <img :src="`${iconUrl}${item.currency_code.toLowerCase()}.png`" height="30" alt="" class="cc mr-2 font-large-2 warning d-none d-md-block">
                        </div>				
                      </div>				
                      <div class="ml-3">	
                        <div class="text-dark-75 font-weight-bold line-height-sm">{{ item.currency_code }}</div>		
                      </div>
                    </div>
                  </span>
                </td>
                <td>{{ item.quantity }}</td>
                <td class="text-muted">{{ item.duration }} {{ textDuration(item.durationType) }}</td>
                <td>
                  <span style="width: 250px;">
                    <div class="d-flex align-items-center">
                      <div class="ml-2">
                        <div class="text-primary font-weight-bold line-height-sm">{{ item.end_date.date  | dateFormat }}</div>
                        <div class="font-size-sm text-dark-50">{{ item.time_ago }}</div>
                      </div>
                    </div>
                  </span>
                </td>
                <td>
                  <span
                      :class="['label label-lg label-inline', item.status === 0 ? 'label-light-danger' : item.status === 1 ? 'label-light-warning' : 'label-light-primary'] ">
                    {{ item.status === 0 ? 'Close' : item.status === 1 ? 'Waiting' : ' In Progress' }}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import dateFormat from '@/config/_date-format';
import { mapState } from 'vuex';
import DatatableDropdown from '@/components/datatable/Dropdown.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import $ from "jquery";


export default {
  name: 'StrikerList',
  data() {
    return {
      isLoading:true,
      lastTransactionsIntervalId: null,
      iconUrl: "https://cdn.test.ofinans.com/images/currency/"
    }
  },
  components: {
    Breadcrumb,
    DatatableDropdown
  },
  filters: {
    dateFormat
  },
  computed: {
    ...mapState({
      skmatch: state => state.striker.skmatch,
      lang: (state) => state.global.lang
    }),
    breadcrumb_data() {
      return {
        title:  this.$t('miracle_deal.title'),
        subtitle: [
          this.$t('miracle_deal.last_transactions')
        ]
      }
    }
  },
  methods: {
    textDuration(id) {
      switch (id) {
        case 0:
          return 'min';
        case 1:
          return 'h';
        case 2:
          return 'd';
        case 3:
          return 'w';
        case 4:
          return 'm';
        default:
          return 'y';
      }
    },
    getLastTransactions() {
      const self = this;
      self.$store.dispatch('striker/GET_LAST_TRANSACTION').then(function () {
        self.isLoading = false;
        self.$nextTick(function (){
          var fileName = 'last-transactions';
          $(self.$refs['kt_datatable']).DataTable().destroy();
          $(self.$refs['kt_datatable']).DataTable({
            responsive: true,
            dom: 'Blfrtip',
            buttons: ['copy', 'csv', 'excel', 'pdf', 'print'].map(el => (
              {
                extend: el,
                filename: fileName
              }
            )),
            initComplete: function() {
              $('.dt-buttons').hide();
            },
            order: [[ 0, 'desc' ]],
            pageLength: 10,
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
            columnDefs: [
              { orderable: false, targets: Array.from({ length:5 },(v,k) => k+1) },
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 }
            ],
            language: {
              url: `/assets/languages/datatable/${this.lang}.json`
            }
          });
          $("#printExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-print").trigger("click");
          });
          $("#copyExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-copy").trigger("click");
          });
          $("#excelExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-excel").trigger("click");
          });
          $("#csvExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-csv").trigger("click");
          });
          $("#pdfExport").on("click", function(e) {
            e.preventDefault();
            $(".buttons-pdf").trigger("click");
          });
        })
      })
    },
    refreshLastTransactions() {
      clearInterval(this.lastTransactionsIntervalId);
      this.lastTransactionsIntervalId = setInterval(() => {
        this.getLastTransactions()
      }, 60*1000);
    }
  },
  created() {
    this.getLastTransactions();
    this.refreshLastTransactions();

  },
  destroyed() {
    clearInterval(this.lastTransactionsIntervalId);
  },
  watch: {
    $route: function () {
      this.getLastTransactions();
      this.refreshLastTransactions();
    },
  },
}
</script>
<style lang="scss">
</style>
